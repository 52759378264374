.search-result {
  padding: 10px 20px;
  color: black;
  text-decoration: none;
}

.search-result img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.search-result:hover {
  background-color: #efefef;
}
